import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props) {
 return (
  <Box sx={{position: 'relative', display: 'inline-flex'}}>
   <CircularProgress variant="determinate" {...props} size={60} />
   <Box
    sx={{
     top: 0,
     left: 0,
     bottom: 0,
     right: 0,
     position: 'absolute',
     display: 'flex',
     alignItems: 'center',
     justifyContent: 'center',
    }}
   >
    <Typography variant="caption" component="div" color="text.secondary">
     {`${Math.round(props.value)}%`}
    </Typography>
   </Box>
  </Box>
 );
}

export default function CircularWithValueLabel() {
 const [progress, setProgress] = React.useState(10);

 React.useEffect(() => {
  const timer = setInterval(() => {
   setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 20));
  }, 600);
  return () => {
   clearInterval(timer);
  };
 }, []);

 return <div style={{display: "grid", placeContent: "center", height: "100vh"}}>
  <CircularProgressWithLabel value={progress}/>
 </div>;
}
