import React from "react";

const SideBar = () => {
 return (
  <div style={{maxWidth: '60px', width: '60px', position: 'absolute'}}>
   <div className="clMenu" id="divMainIntMenu" style={{height: '100vh'}}>
    <ul>
     <li id="liFloLogo" tabid="" className="" title="FloCareer">
      <img
       src="https://www.flocareer.com/images/flo2.0/icon-flo-logo-only.png"
       alt="FloCareer"
       className="clLogoOnly"
      />
     </li>
     <li id="liLiveInt" tabid="divTabMainLiveInt" className="active" title="Live Interview">
      <svg
       className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge"
       focusable="false"
       viewBox="0 0 24 24"
       aria-hidden="true"
      >
       <path
        d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"></path>
      </svg>
     </li>
     <li id="liResume" tabid="divTabMainResume" className="" title="Resume">
      <svg
       className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge"
       focusable="false"
       viewBox="0 0 24 24"
       aria-hidden="true"
      >
       <path
        d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"></path>
      </svg>
     </li>
     <li id="liJd" tabid="divTabMainJD" className="" title="Job Description">
      <svg
       className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge"
       focusable="false"
       viewBox="0 0 24 24"
       aria-hidden="true"
      >
       <path
        d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z"></path>
      </svg>
     </li>
     <li id="liCmpPro" tabid="divTabMainCP" className="" title="Company Profile">
      <svg
       className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge"
       focusable="false"
       viewBox="0 0 24 24"
       aria-hidden="true"
      >
       <path
        d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"></path>
      </svg>
     </li>
    </ul>
   </div>
  </div>
 );
};
export default SideBar;
