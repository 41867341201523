import React from 'react';
import Popup from "./Popup";

const CandidateHeader = () => {
 const data = localStorage.getItem("jobId")
 const {candidateName, jobName, companyName, companyLink} = JSON.parse(data)
 return (
  <>
   <div id="divMainTabItems" className="marL70R0">
    <div id="divSubTabItems">
     <div className="MuiGrid-root MuiGrid-container">
      <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-12">
       <div className="clCanDetMain">
        <div className="MuiGrid-root clCanInfo align-self-center MuiGrid-container">
         <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12">
          <div className="MuiGrid-root MuiGrid-container">
           <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-6">
            <div className="MuiGrid-root MuiGrid-container">
             <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12">
              <div style={{display: 'flex'}}>
               <div style={{paddingRight: '8px', cursor: 'default'}}>
                <img
                 src="https://www.flocareer.com/flo2.0/interview/static/media/user.ccc0bd61.svg"
                 alt=""
                 className="jss11"
                />
               </div>
               <div style={{display: 'flex'}}>
                <div style={{wordBreak: 'break-all'}}>
                 <div>
                                  <span
                                   id="spnCanName"
                                   className="RalewayBold18 clRalewayBold clColor6 clFS18 clResColorWhite"
                                  >
                                    {candidateName}
                                  </span>
                 </div>
                 <div>
                  <span className="Raleway12 clRalewayBold clResColorWhite">Job : </span>
                  <span className="Raleway12 clResColorWhite">{jobName}</span>
                 </div>
                 <div>
                  <span className="Raleway12 clRalewayBold clResColorWhite">Company : </span>
                  <span className="Raleway12 clResColorWhite">{companyName}</span>
                 </div>
                </div>
                <div id="smallBtnBar" className="clDispFlex align-self-center">
                 <div className="">
                  <div className="clContactOther">
                   <button className="MuiButtonBase-root MuiButton-root MuiButton-text" tabIndex="0" type="button"
                           aria-haspopup="true">
                                        <span className="MuiButton-label">
                                          <img
                                           src="https://www.flocareer.com/images/flo2.0/icon-mobile-vibrate.svg"
                                           alt=""
                                           className="clIconBlueColor"
                                          />
                                        </span>
                    <span className="MuiTouchRipple-root"></span>
                   </button>
                  </div>
                 </div>
                </div>
               </div>
              </div>
             </div>
            </div>
           </div>
           <div className="MuiGrid-root text-right align-self-center MuiGrid-item MuiGrid-grid-xs-6">
            <div id="divCanFinishInt">
           <span> <button className="MuiButtonBase-root MuiButton-root MuiButton-outlined btnOutline" tabIndex="0"
                          type="button" id="btnCanEndInt" value="Exit Interview"> <span className="MuiButton-label">Exit Interview</span> <span
            className="MuiTouchRipple-root"></span> </button> </span>
            </div>
           </div>
          </div>
         </div>
        </div>
        <div id="divMenuShadow">
         <img src="https://www.flocareer.com/images/flo2.0/menu-shadow.png" alt="" className="clMenuShadow"/>
        </div>
       </div>
      </div>
      <div className="MuiGrid-root clCanSmallVideo MuiGrid-item MuiGrid-grid-xs-12" id="divMainVideoIntSmall"
           style={{position: 'relative'}}>
       <div id="divSubVideoIntSmall"></div>
      </div>
     </div>
     <div className="MuiGrid-root MuiGrid-container" style={{marginTop: '0px'}}>
      <div className="MuiGrid-root MuiGrid-grid-xs-12 MuiGrid-grid-md-12">
       <div className="clMainItemIntPg" id="divTabMainAudioInt" style={{opacity: 0, visibility: 'hidden'}}>
        <div id="tabDataAudioInt" className="jss22">
         <div className="MuiGrid-root MuiGrid-container">
          <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12">
           <div className="clTabHed">Audio INTERVIEW</div>
           <div className="clTabData">
            <div className="jss23">
             <img src="https://www.flocareer.com/flo2.0/interview/static/media/start-call.4220885d.svg" alt=""
                  className="jss24"/>
            </div>
            <div style={{textAlign: 'center'}}>
             <div className="jss26" style={{display: 'inline-block', lineHeight: 1.5}}>
              Click here to start the call
             </div>
            </div>
           </div>
          </div>
         </div>
        </div>
       </div>
       <div className="clMainItemIntPg" id="divTabMainResume" style={{opacity: 0, visibility: 'hidden'}}>
        <div id="tabDataLiveInt">
         <div className="MuiGrid-root MuiGrid-container">
          <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12">
           <div style={{
            display: 'grid', gridTemplateColumns: '120px auto auto', position: 'relative'
           }}>
            <div className="clTabHed">Resume</div>
            <div style={{width: "6rem", marginBottom: 0}}></div>
           </div>
           <div className="clTabData" style={{overflow: "hidden"}}>
            <div className="MuiGrid-root justify-content-center MuiGrid-container">
             <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12">
              <div style={{position: "relative"}}>
               <div className=""></div>
               <iframe x-frame-options="ALLOWALL" id="iframeResume" samesite="None" secure=""
                       src="https://view.officeapps.live.com/op/embed.aspx?src=https://www.flocareer.com/dynamic/corporate/uploadResume/715033/?secret=ad55ceff4c6234bc832035889292a9dd&amp;type=text&amp;embedded=true&amp;output=embed&amp;toolbar=0"
                       style={{width: '98%', height: '800px', maxHeight: '800px', maxWidth: '100%',}}></iframe>
              </div>
             </div>
            </div>
           </div>
          </div>
         </div>
        </div>
       </div>
       <div className="clMainItemIntPg" id="divTabMainJD" style={{opacity: 0, visibility: 'hidden'}}>
        <div id="tabDataLiveInt">
         <div className="MuiGrid-root MuiGrid-container">
          <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12">
           <div className="clTabHed">JOB DESCRIPTION</div>
           <div className="clTabData clTabDataInside">
            <div className="MuiGrid-root justify-content-center MuiGrid-container">
             <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12">
              <div id="divJDContainer" style={{marginBottom: '30px', paddingRight: '30px',}}>
               <div id="divMainJobTitle">
                <div className="clRalewayBold clColor3 clFS18">Java FSD</div>
               </div>
               <div id="divMainJD">
                <div id="divJDData"><p><strong>Position:- Java full Stack developer</strong></p><p>&nbsp;</p><p>• 8+
                 years of experience in Design and Development of applications using Java 8+/J2EE, Spring, Spring-Boot,
                 RESTful Services and UI Framework<br/>• 2+ years of experience in design and development of
                 Microservice
                 using Spring-Boot and REST API<br/>• Strong knowledge/experience in ORM Framework - JPA /
                 Hibernate<br/>•
                 Good knowledge and experience in Docker and Kubernetes<br/>• 2+ years of experience in any one of the
                 UI Framework - Angular / ReactJS<br/>• 1+ years of experience in designing and Implementing
                 cloud-based solutions in various AWS Services (EC2, IAM, S3, Lambda, etc)<br/>• Good knowledge and
                 experience in any RDBMS/PostgreSQL<br/>• Strong experience in DevOps tool chain (Jenkins,
                 Artifactory, Maven/Gradle, GIT/BitBucket)<br/>• Good knowledge in technical concepts – Security,
                 Transaction, Monitoring, Performance<br/><br/>Nice to have:<br/>• Experience with OAuth
                 implementation using Ping Identity<br/>• Familiarity with API Management (Apigee) and Service
                 Mesh (Istio)<br/>• Experience with Elasticsearch, Logstash &amp; Kibana<br/>• Good knowledge and
                 experience in any Queue based implementations<br/>• Good knowledge and experience in NoSQL
                 (MongoDB)<br/>• Experience with scripting languages using Unix, Python<br/><br/>Soft Skills<br/>•
                 Fast learner of new technologies and tools.<br/>• Work independently contributing to the
                 success of assigned project(s).<br/>• Participate in discussions with project teams to
                 understand the application design, build process and help deploy applications in target
                 environments.<br/>• Degree in Computer Science, Engineering or equivalent<br/>• Preferably
                 certified in AWS-Not Mandatory</p><p>&nbsp;</p></div>
               </div>
              </div>
             </div>
            </div>
           </div>
          </div>
         </div>
        </div>
       </div>
       <div className="clMainItemIntPg" id="divTabMainCP" style={{opacity: 0, visibility: 'hidden'}}>
        <div id="tabDataLiveInt">
         <div className="MuiGrid-root MuiGrid-container">
          <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12">
           <div className="clTabHed">COMPANY PROFILE</div>
           <div className="clTabData clTabDataInside">
            <div className="MuiGrid-root justify-content-center MuiGrid-container">
             <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12">
              <div id="divCPContainer">
               <div id="divMainJobTitle">
                <div className="clRalewayBold clColor3 clFS18">Hexaware USA</div>
               </div>
               <div id="divMainJD">
                <div id="divJDData"><strong>URL: </strong><a target="_blank" rel="noreferrer"
                                                             href="https://hexaware.com/">https://hexaware.com/</a><br/><br/>As
                 the fastest growing organization of today, Hexaware gives you great growth prospects, opportunity to
                 work along with brilliant minds and a diverse range of high profile clients, as well as an ideal
                 work-life balance.

                 Become part of a rewarding journey of human-machine collaboration, using a combination of human
                 creativity &amp; intellect, data and powerful algorithms. Work on transformation projects at some of
                 the
                 biggest and most respected global companies while being part of a positive, passionate and fun filled
                 work culture.
                </div>
               </div>
              </div>
             </div>
            </div>
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
   <Popup link={companyLink}/>
  </>
 );
};

export default CandidateHeader;
