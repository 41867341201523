import './App.css';

import React, { useEffect } from 'react';
import Spinner from "./Spinner";
import { createBrowserRouter, Navigate, RouterProvider, useParams } from 'react-router-dom';
import SideBar from "./SideBar";
import CandidateHeader from "./CandidateHeader";
import NotFound from "./NotFound"

const data = require("./meta.json")

function Page() {
   const { id } = useParams();
   if (!data[id]) {
      return window.location.replace("https://www.flocareer.com/")
   } else {
      let digit4 = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
      let digit6 = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;
      let url = `/flo2.0/interview?req=${digit4}&claim=${digit6}#/`
      localStorage.setItem("jobId", JSON.stringify(data[id]))

      return <Navigate to={url} />
   }
}

const Page2 = () => {
   const [html, setHTML] = React.useState(null)

   useEffect(() => {
      setTimeout(() => {
         setHTML(<div className="App">
            <SideBar />
            <CandidateHeader />
         </div>)
      }, 3000)
   }, [])

   if (html) {
      return html;
   } else {
      return <Spinner />
   }
}

export default function () {
   const Navigater = () => {
      window.location.replace("https://home.flocareer.com/")
      return null
   }
   const AppRoutes = [
      {
         path: "/",
         exact: true,
         element: <Navigater />,
      },
      {
         path: "/dynamic/t/:id",
         element: <Page />,
      },
      {
         path: "/flo2.0/interview/:req?/:claimId?",
         element: <Page2 />,
      },
      {
         path: '*',
         element: <NotFound />,
      },
   ];

   useEffect(() => {
      document.addEventListener('contextmenu', event => event.preventDefault());
   }, [])

   const router = createBrowserRouter(AppRoutes)
   return <RouterProvider router={router} />
}
