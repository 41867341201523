import * as React from 'react';
import {useEffect, useState} from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import {makeStyles} from "@mui/styles";
import {DialogActions, DialogContent, Divider} from "@mui/material";


export default function SimpleDialog(props) {
 const {link} = props;

 const handleClose = () => {
  console.log("")
 };


 const [style, setStyle] = useState({
  backdropFilter: 'blur(0)',
  backgroundColor: 'rgba(0, 0, 30, 0.4)',
 });

 useEffect(() => {
  const timeoutId = setTimeout(() => {
   setStyle({
    ...style,
    backdropFilter: 'blur(5px)',
   });
  }, 2000); // 2 seconds delay

  return () => clearTimeout(timeoutId);
 }, []);

 const useStyles = makeStyles(() => ({
  backDrop: style
 }));

 const classes = useStyles();

 return (
  <Dialog
   onClose={handleClose}
   open={true}
   slotProps={{
    backdrop: {
     classes: {
      root: classes.backDrop,
     },
    }
   }}
   fullWidth={true}
   maxWidth={"sm"}
  >
   <DialogTitle>
    <Typography variant={"h6"}>
     Info
    </Typography></DialogTitle>

   <Divider/>
   <DialogContent>
    Your interview has been finished
   </DialogContent>
   <Divider/>
   <DialogActions>
    <button onClick={() => window.location.replace(link)}
            className="MuiButtonBase-root MuiButton-root MuiButton-text jss6" tabindex="0" type="button"><span
     className="MuiButton-label">OK</span><span className="MuiTouchRipple-root"></span></button>
   </DialogActions>
  </Dialog>
 );
}
